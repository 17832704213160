<template>
  <div>
    <b-row cols="2">
      <b-col
        cols="12"
        lg="6"
        md="12"
        sm="12"
        xs="12"
        id="project-table-card-col"
      >
        <b-card id="project-table-card" class="card-height h-100">
          <loading
            :active.sync="loading"
            :can-cancel="true"
            :is-full-page="false"
            color="#4285f4"
            loader="dots"
          />
          <!-- table -->
          <b-table
            v-show="!loading"
            v-if="projectResponse !== ''"
            :items="tableItems"
            :fields="tableColumns"
            :sort-by.sync="sortBy"
            :sort-desc.sync="isSortDirDesc"
            show-empty
            :per-page="perPage"
            :current-page="currentPage"
            empty-text="No matching records found"
            id="project"
            class="mt-1"
            responsive
          >
            <template #head()="data">
              <span v-if="data.label == 'Projects'">
                {{ data.label }}
              </span>
              <span v-else class="d-flex justify-content-center">{{
                data.label
              }}</span>
            </template>

            <template #cell(projects)="data">
              <span
                :title="data.item.projects"
                class="d-flex justify-contet-start"
                v-if="data.item.projects"
              >
                {{
                  data.item.projects.length > 20
                    ? data.item.projects.substring(0, 18) + "..."
                    : data.item.projects
                }}
              </span>
            </template>
            <template #cell(cost)="data">
              <span class="d-flex justify-content-center">
                {{ data.item.cost | toCurrency(data.item.currency) }}
              </span>
            </template>
            <template #cell(discounts)="data">
              <span class="d-flex justify-content-center">
                {{ data.item.discounts | toCurrency(data.item.currency) }}
              </span>
            </template>
            <template #cell(total)="data">
              <span class="d-flex justify-content-center">
                {{ data.item.total | toCurrency(data.item.currency) }}
              </span>
            </template>
          </b-table>

          <b-card
            v-show="!loading"
            class="border-top border-bottom align-end size"
          >
            <b-row>
              <!-- Pagination -->
              <b-col
                cols="12"
                lg="8"
                md="8"
                sm="8"
                xs="8"
                v-show="!loading"
                class="d-flex align-items-center justify-content-start"
              >
                <b-pagination
                  limit="3"
                  v-model="currentPage"
                  :total-rows="rows"
                  :per-page="perPage"
                  first-number
                  last-number
                  prev-class="prev-item"
                  next-class="next-item"
                  aria-controls="my-table"
                  align="right"
                  class="mb-0"
                >
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </b-col>
              <b-col
                cols="12"
                lg="4"
                md="4"
                sm="4"
                xs="4"
                class="d-flex align-items-center justify-content-end"
                style="font-weight: bold"
                v-if="total"
              >
                Total: {{ total.total | toCurrency(total.currency) }}
              </b-col>
            </b-row>
          </b-card>
        </b-card>
      </b-col>
      <b-col cols="12" lg="6" md="12" sm="12" xs="12" id="bar-chart-view">
        <b-card class="card-height h-100">
          <loading
            :active.sync="loading"
            :can-cancel="true"
            :is-full-page="false"
            color="#4285f4"
            loader="dots"
          />
          <Bar
            v-if="projectResponse && barChartData"
            v-show="!loading"
            :chart-options="chartOptions"
            :chart-data="barChartData"
          />
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { Bar } from "vue-chartjs/legacy";
import Loading from "vue-loading-overlay";
import store from "@/store";
import "vue-loading-overlay/dist/vue-loading.css";
import {getDatasetWithZerosBarChart} from "@/@core/utils/utils";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
} from "chart.js";
import {
  BCard,
  BRow,
  BCol,
  BButton,
  BDropdown,
  BDropdownItem,
  BProgress,
  BCardText,
  BFormGroup,
  BFormInput,
  BFormDatepicker,
  BDropdownDivider,
  BTable,
  BPagination,
  BSpinner,
} from "bootstrap-vue";
import { getUserData } from "@/auth/utils";
ChartJS.register(
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale
);

export default {
  name: "BarChart",
  components: {
    Loading,
    Bar,
    BCard,
    BRow,
    BCol,
    BButton,
    BCardText,
    BDropdown,
    BDropdownItem,
    BProgress,
    BFormDatepicker,
    BFormGroup,
    BFormInput,
    BDropdownDivider,
    BTable,
    BPagination,
    BSpinner,
  },
  props: {
    monthDays: {
      type: Array,
    },
    projectResponse: {
      type: Object,
    },
    gcpSpendResponse: {
      type: Object,
    },
    loading: {
      type: Boolean,
    },
  },
  computed: {
    rows() {
      return this.tableItems.length;
    },
  },
  data() {
    return {
      isFullScreen: false,
      tableColumns: [
        { key: "projects", sortable: true },
        { key: "cost", sortable: true },
        { key: "discounts", sortable: true },
        { key: "total", sortable: true },
      ],
      sortBy: "",
      isSortDirDesc: true,
      total: 0,
      tableItems: [],
      currentPage: 1,
      perPage: 10,
      barChartData: {
        labels: [],
        datasets: [],
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            labels: {},
          },
        },
        scales: {
          x: {
            time: {
              unit: "day",
            },
            grid: {
              tickColor: "white",
            },
            title: {
              display: true,
              text: "Dates of month",
              font: {
                size: 14,
                weight: "bold",
                family: "Montserrat, Helvetica, Arial, serif",
              },
            },
            ticks: {},
          },
          y: {
            grid: {
              tickColor: "white",
            },
            title: {
              display: true,
              text: "Cost",
              font: {
                size: 14,
                weight: "bold",
                family: "Montserrat, Helvetica, Arial, serif",
              },
            },
            ticks: {
              callback: function (value) {
                if (store.state.app.currencyType == "USD") {
                  return "$ " + value;
                } else if (store.state.app.currencyType == "GBP") {
                  return "£ " + value;
                } else if (store.state.app.currencyType == "EUR") {
                  return "€ " + value;
                }
              },
            },
          },
        },
      },
    };
  },
  beforeMount() {
    if (store.state.appConfig.layout.skin == "dark") {
      this.chartOptions.plugins.legend.labels.color = "#FFFFFF";
      this.chartOptions.scales.x.title.color = "#FFFFFF";
      this.chartOptions.scales.y.title.color = "#FFFFFF";
      this.chartOptions.scales.x.ticks.color = "#D0D2D6";
      this.chartOptions.scales.y.ticks.color = "#D0D2D6";
    } else {
      this.chartOptions.plugins.legend.labels.color = "#5e5873";
      this.chartOptions.scales.x.title.color = "#5e5873";
      this.chartOptions.scales.y.title.color = "#5e5873";
      this.chartOptions.scales.x.ticks.color = "#6E6B7B";
      this.chartOptions.scales.y.ticks.color = "#6E6B7B";
    }
  },
  watch: {
    projectResponse: function () {
      this.getUniqueProject();
    },
    gcpSpendResponse: function () {
      this.getCurrentMonth();
      this.getDailySpend();
    },
    "$store.state.app.userData.default_region_id": {
      handler() {
        this.currentPage = 1;
      },
    },
    "$store.state.appConfig.layout.skin": {
      handler() {
        if (store.state.appConfig.layout.skin == "dark") {
          this.chartOptions.plugins.legend.labels.color = "#FFFFFF";
          this.chartOptions.scales.x.title.color = "#FFFFFF";
          this.chartOptions.scales.y.title.color = "#FFFFFF";
          this.chartOptions.scales.x.ticks.color = "#D0D2D6";
          this.chartOptions.scales.y.ticks.color = "#D0D2D6";
        } else {
          this.chartOptions.plugins.legend.labels.color = "#5e5873";
          this.chartOptions.scales.x.title.color = "#5e5873";
          this.chartOptions.scales.y.title.color = "#5e5873";
          this.chartOptions.scales.x.ticks.color = "#6E6B7B";
          this.chartOptions.scales.y.ticks.color = "#6E6B7B";
        }
      },
      immediate: false,
    },
  },
  methods: {
    fullScreenView() {
      let elem = document.getElementById("bar-chart-view");
      document.getElementById("bar-chart").style.display = "";
      /* Function to open fullscreen mode */
      this.isFullScreen = true;
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.webkitRequestFullscreen) {
        /* Safari */
        elem.webkitRequestFullscreen();
      } else if (elem.msRequestFullscreen) {
        /* IE11 */
        elem.msRequestFullscreen();
      }
    },
    closeScreenView() {
      /* Function to open fullscreen mode */
      this.isFullScreen = false;
      if (document.exitFullscreen) {
        document.getElementById("bar-chart").style.display = "block";
        document.getElementById("bar-chart").style.height = "500px";

        document.exitFullscreen();
      } else if (document.webkitExitFullscreen) {
        /* Safari */
        document.webkitRequestFullscreen();
      } else if (document.msExitFullscreen) {
        /* IE11 */
        document.msExitFullscreen();
      }
    },
    getCurrentMonth() {
      this.barChartData = {
        ...this.barChartData,
        labels: this.monthDays,
      };
    },
    getUniqueProject() {
      if (this.projectResponse.data) {
        this.tableItems = [];
        this.total = 0;

        this.projectResponse.data.table_data.sort(function (a, b) {
          return parseFloat(b.total.total) - parseFloat(a.total.total);
        });

        this.tableItems = this.projectResponse.data.table_data;
        this.total = this.projectResponse.data.total;
      }
    },
    getDailySpend() {
      if (this.gcpSpendResponse.data) {
        this.barChartData.datasets = [];
        this.barChartData.datasets = 
          getDatasetWithZerosBarChart(this.monthDays,this.gcpSpendResponse.data.bar_data,getUserData().default_region_id)
      }
    },
  },
};
</script>
<style scoped>
#project-table-card {
  margin-bottom: 20px;
}

#project-table-card-col {
  padding-bottom: 2rem;
}

.card-height {
  min-height: 400px;
  max-height: 100%;
}

#bar-chart {
  height: 100%;
}

#bar-chart-view {
  padding-bottom: 2rem;
}

#project-table-card > div > div.card.bg-light {
  margin-bottom: 0 !important;
}

.fullscreen {
  cursor: pointer;
}
</style>
<style>
.table th {
  padding: 0.72rem 1rem;
}
</style>
